<script setup lang="ts">
import { useGtm } from "@gtm-support/vue-gtm";
import type { PopupTypeForm } from "~/models/Popup";

const {
  label,
  url,
  type,
  picto,
  external,
  color,
  sticky,
  classicLink,
  readingButton,
  popup,
  fakeCta,
  isSubscribe,
  isDemo,
  isCustom,
  dataAttr,
  size,
  fullWidth,
} = defineProps<{
  label: string;
  url?: string;
  type: string;
  picto?: string;
  size?: string;
  color?: string;
  external?: boolean;
  sticky?: boolean;
  classicLink?: boolean;
  readingButton?: boolean;
  aside?: boolean;
  popup?: PopupTypeForm;
  fakeCta?: boolean;
  isSubscribe?: boolean;
  isDemo?: boolean;
  isCustom?: boolean;
  dataAttr?: string;
  fullWidth?: boolean;
}>();
const popupDialog = useDialog();

const { isIos, isMobile } = useDevice();
const route = useRoute();
const gtm = useGtm();

const isNuxtLink = computed(() => {
  return isSubscribe || sticky || external || isCustom;
});

const pageName = computed(() => {
  const formattedPath = route.path
    .replace(/^\//, "")
    .replace(/\//g, "_")
    .replace(/-/g, "_");

  return formattedPath;
});

const pushDataLayerInfos = (): void => {
  const os: string = isIos ? "ios" : "android";
  let ctaType: string = isDemo ? "demo" : "";
  ctaType = isSubscribe ? "signup" : ctaType;
  const osPart: string = isMobile ? `_${os}` : "";
  gtm?.push({
    hero_banner_cta: `${ctaType}_${pageName.value}${osPart}`,
  });
};

async function onClick(e: Event) {
  e.preventDefault();

  if (isDemo || isSubscribe) {
    pushDataLayerInfos();
  }

  if (fakeCta) return;

  if (popup) {
    popupDialog.open();
  } else {
    await navigateTo(url, { external: !!external });
  }
}
</script>
<template>
  <button
    v-if="!isNuxtLink"
    class="btn-link"
    :class="[
      type,
      size,
      { picto: picto },
      color,
      { sticky: sticky },
      { 'classic-link': classicLink },
      { reading: readingButton },
      { aside: aside },
      { 'full-width': fullWidth },
    ]"
    :data-attr="dataAttr"
    @click="onClick"
  >
    <i v-if="picto" :class="picto" />
    <span class="btn-text">
      {{ label }}
    </span>
    <span v-if="!picto && !classicLink && readingButton" class="btn-link-container-picto">
      <i role="presentation" class="extra-arrow-read" />
    </span>
  </button>
  <CustomNuxtLink
    v-else
    :to="url"
    :external="external"
    class="btn-link"
    :class="[
      type,
      size,
      { picto: picto },
      color,
      { sticky: sticky },
      { 'classic-link': classicLink },
      { reading: readingButton },
      { aside: aside },
      { 'full-width': fullWidth },
    ]"
    :data-attr="`cta_${formatTextToSlug(label)}`"
  >
    <i v-if="picto" :class="picto" />
    <span class="btn-text">
      {{ label }}
    </span>
    <span v-if="!picto && !classicLink && readingButton" class="btn-link-container-picto">
      <i role="presentation" class="extra-arrow-read" />
    </span>
  </CustomNuxtLink>

  <ClientOnly v-if="popup != undefined">
    <Teleport to="#portal-popup">
      <PopupTypeForm
        :src="popup.src"
        :open="popupDialog.isOpen"
        @close="popupDialog.close"
      />
    </Teleport>
  </ClientOnly>
</template>
<style lang="scss" scoped>
.btn-link {
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  transition: all ease-in-out 0.3s;
  max-height: 35px;
  min-height: 32px;
  position: relative;
  text-decoration: none;
  width: fit-content;
  min-width: 140px;
  justify-content: center;
  padding: 10px 25px;
  @include mq(desktop) {
    max-height: 52px;
    min-height: 52px;
    border-radius: 50px;
  }
  &.full-width {
    width: 100%;
  }
  &-container-picto {
    display: flex;
    align-items: center;
    i {
      width: 15px;
      height: 15px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 5px;
      position: relative;
      right: 0;
      transition: all ease-in-out 0.3s;
      &.extra-arrow-read {
        font-size: 13px;
        color: $white;
        transition: all ease-in-out 0.3s;
      }
    }
  }

  .btn-text {
    text-transform: uppercase;
    font-size: 15px;
    transition: all ease-in-out 0.3s;
    color: $white;
    @include mq(desktop) {
      font-size: 17px;
    }
  }
  &.primary {
    background-color: $primary-color;
    color: $white;
    transition: all ease-in-out 0.3s;
  }
  &.candidat,
  &.hidf-lyon {
    background-color: $primary-color;
    border: 1px solid transparent;
    &:hover {
      background-color: $white;
      border-color: $primary-color;
      .btn-text,
      i {
        color: $primary-color;
      }
    }
    &-dark {
      background-color: $primary-color-800;
      border: 1px solid transparent;
      &:hover {
        background-color: $white;
        border-color: $primary-color-800;
        .btn-text,
        i {
          color: $primary-color-800;
        }
      }
    }
    &-white {
      background-color: var(--showcase-color-additional-light);
      border: 1px solid var(--showcase-color-additional-light);
      .btn-text,
      i {
        color: var(--showcase-color-primary-200);
        &:before {
          color: var(--showcase-color-primary-200);
        }
      }
      &.picto {
        i {
          &:before {
            color: var(--showcase-color-primary-200);
          }
        }
      }
      &.with-border {
        border: 1px solid var(--showcase-color-primary-200);
      }
    }
    &.small-banner {
      padding-left: 0;
      margin-left: var(--showcase-size-100);
      min-width: 115px;
      @include mq(desktop) {
        min-width: 140px;
      }
      i {
        width: 30px;
        margin-left: -2px;
        @include mq(desktop) {
          width: 25px;
          margin-left: -8px;
          margin-right: 8px;
        }
        &:before {
          font-size: 25px;
        }
      }
      .btn-text {
        font-size: 10px;
        @include mq(desktop) {
          font-size: 12px;
        }
      }
    }
  }
  &.recruteur,
  &.bu {
    background-color: $etab-color;
    border: 1px solid transparent;
    &:hover {
      background-color: $white;
      border-color: $etab-color;
      .btn-text,
      i {
        color: $etab-color;
        &:before {
          color: $etab-color;
        }
      }
    }
    &-dark {
      background-color: $etab-color-dark;
      border: 1px solid transparent;
      &:hover {
        background-color: $white;
        border-color: $etab-color-dark;
        .btn-text,
        i {
          color: $etab-color-dark;
        }
      }
    }
    &.transparent {
      background-color: var(--showcase-color-additional-light);
      border-color: var(--showcase-color-secondary-200);
      .btn-text,
      i {
        color: var(--showcase-color-secondary-200);
        &:before {
          color: var(--showcase-color-secondary-200);
          transition: all ease-in-out 0.6s;
        }
      }
    }
    &.picto {
      &:hover {
        background-color: $secondary-color;
        border-color: $secondary-color;
        .btn-text,
        i {
          color: $white;
          &:before {
            color: $white;
          }
        }
      }
    }
  }
  &.picto {
    padding: 20px var(--showcase-size-150);
    @include mq(desktop) {
      padding: 10px var(--showcase-size-150) 10px 10px;
    }
    i {
      &:before {
        font-size: 28px;
        color: $white;
        margin-right: var(--showcase-size-075);
        @include mq(desktop) {
          font-size: 30px;
        }
      }
    }
    &.candidat,
    &.hidf-lyon {
      &:hover {
        border-color: var(--showcase-color-primary-200);
        i {
          &:before {
            color: var(--showcase-color-primary-200);
          }
        }
      }
    }
    &.sticky {
      border: 1px solid var(--showcase-color-additional-light);
      padding-right: var(--showcase-size-225);
      z-index: 10001;
      min-width: 245px;
      display: flex;
      justify-content: left;
      i {
        animation: breathing 3s ease-out infinite normal;
        &:before {
          font-size: var(--showcase-size-225);
        }
      }
      &:hover {
        border-color: var(--showcase-color-additional-light);
      }
      &.candidat,
      &.hidf-lyon {
        &:hover {
          border-color: var(--showcase-color-primary-200);
          i {
            &:before {
              color: var(--showcase-color-primary-200);
            }
          }
        }
      }
    }
  }
  &.small {
    min-width: 140px;
    padding: 10px 0 10px 10px;
    justify-content: center;
    @include mq(desktop) {
      max-height: 34px;
      min-height: 34px;
      border-radius: 50px;
    }
    .btn-text {
      font-size: 12px;
    }
  }
  &.medium {
    min-width: 160px;
    align-items: center;
    justify-content: center;
    @include mq(desktop) {
      max-height: 45px;
      min-height: 45px;
      border-radius: 50px;
    }
    .btn-text {
      font-size: 14px;
      line-height: 21px;
    }
  }
  &.large {
    max-height: 43px;
    min-height: 43px;
    min-width: 240px;
    .btn-text {
      font-size: var(--showcase-size-100);
    }
  }
  &.classic-link {
    background-color: transparent;
    border: 0;
    .btn-text {
      color: var(--showcase-color-additional-dark);
      text-transform: none;
      font-size: var(--showcase-size-075);
      text-decoration: underline;
    }
    i {
      display: none;
    }
    &:hover {
      background-color: transparent;
      border: 0;
      i {
        display: none;
      }
    }
  }
  &.reading {
    .btn-text {
      margin-right: $gutter;
    }
  }
  &.aside {
    .btn-text {
      font-size: 15px;
    }
  }
}

@keyframes breathing {
  0% {
    transform: scale(0.9);
  }

  25% {
    transform: scale(1);
  }

  60% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(0.9);
  }
}
</style>
